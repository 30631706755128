import { useState, useCallback } from "react";
import { getScoreDisplay } from "./ScoreList";

export const NUM_GUESSES = 6;
export const MS_PER_DAY = 86400000;
export const MS_PER_HOUR = 3600000;
export const MS_PER_MINUTE = 60000;
export const MS_PER_SECOND = 1000;
export const START_MS = 1671339600000;

const defaultEmojiStr = "🔴🟠🟡🟢🔵🟣";

export function getFormattedCountdown(ms) {
  let t = ms;
  const h = Math.floor(t / MS_PER_HOUR)
    .toString()
    .padStart(2, "0");
  t %= MS_PER_HOUR;
  const m = Math.floor(t / MS_PER_MINUTE)
    .toString()
    .padStart(2, "0");
  t %= MS_PER_MINUTE;
  const s = Math.floor(t / MS_PER_SECOND)
    .toString()
    .padStart(2, "0");
  return `${h}:${m}:${s}`;
}

export function getTimeToNextDay(todayIndex) {
  if (getTodayIndex() > todayIndex) return 0;
  return MS_PER_DAY - getTimeSinceLastDay();
}

export function getTimeSinceLastDay() {
  const alignedTime = Date.now() - START_MS;
  return alignedTime % MS_PER_DAY;
}

export function getTodayIndex() {
  return getDayIndex(Date.now());
}

// expects Date object, Date.now(), or date.getTime()
export function getDayIndex(time) {
  return Math.floor((time - START_MS) / MS_PER_DAY);
}

export function createShareEmojiBlock(options, scoreList, emojiStr) {
  const orderedEmojis = [...(emojiStr ?? defaultEmojiStr)];
  const emojiDict = {};
  const sortedOptions = [...options];
  sortedOptions.sort((a, b) => a.value - b.value);
  for (let i = 0; i < sortedOptions.length; i++) {
    emojiDict[sortedOptions[i].label] = orderedEmojis[i]
  }

  let shareText = "";
  for (let i = 0; i < scoreList.length; i++) {
    const { score, guessLabels } = scoreList[i];
    shareText += guessLabels.map(label => emojiDict[label]).join("");
    shareText += " " + getScoreDisplay(score) + "\n";
  }
  return shareText;
}

const winMessages = [
  [],
  ["Awesome!", "Perfect!"],
  ["Wow!", "Amazing!"],
  ["Superb!", "Very Nice!"],
  ["Nice work!", "Way to go!"],
  ["Good job!", "Great work!"],
  ["Phew!"],
];

export function getWinMessage(score) {
  const arr = winMessages[score];
  return arr[getTodayIndex() % arr.length];
}

// a and b are Numbers
export function isOrdered(a, b) {
  return a < b;
}

// arr is an array of Numbers
export function gradeOrder(arr) {
  if (!Array.isArray(arr)) return 0;

  // check for repeats or not enough guesses
  const answerSet = new Set(arr);
  if (answerSet.size !== arr.length) return 0;

  let score = 0;
  let total = 0;

  for (let i = 0; i < arr.length - 1; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      total++;
      if (isOrdered(arr[i], arr[j])) score++;
    }
  }
  return score / total;
}

// https://gist.github.com/gragland/2970ae543df237a07be1dbbf810f23fe (see 2022 discussion)
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json
      if (item != null) {
        return JSON.parse(item);
      }
      // if none, save and return initialValue
      window.localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback((value) => {
    try {
      setStoredValue(latestValue => {
        const valueToStore = value instanceof Function ? value(latestValue) : value
        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        return valueToStore;
      });
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  }, [key, storedValue]);
  return [storedValue, setValue];
}
