import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faShareNodes, faSun, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getFormattedCountdown, MS_PER_DAY, createShareEmojiBlock, START_MS, getTimeToNextDay } from "./util";

import "./Dialogs.css";
import { ScoreBox } from "./ScoreList";

function CustomDialog({ isOpen, setClosed, children }) {
  const ref = useRef();
  useEffect(() => {
    if (isOpen && !ref.current.open) {
      ref.current.showModal();
    } else if (ref.current.open) {
      ref.current.setAttribute("closing", "");
      ref.current.addEventListener(
        "animationend",
        () => {
          ref.current.removeAttribute("closing");
          ref.current.close();
        },
        { once: true }
      );
    }
  }, [isOpen]);

  return (
    <dialog className="dialog" ref={ref} onClose={() => setClosed()} onClick={() => setClosed()}>
      <div className="dialog-inner" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
      <button className="close-btn" onClick={() => setClosed()}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </dialog>
  );
}

function Countdown({ todayIndex, endCallback }) {
  const [countdown, setCountdown] = useState(getFormattedCountdown(todayIndex));
  useEffect(() => {
    const interval = setInterval(() => {
      const countdownMs = getTimeToNextDay(todayIndex);
      setCountdown(getFormattedCountdown(countdownMs));
      if (countdownMs === 0 && endCallback) {
        clearInterval(interval);
        endCallback();
      };
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [todayIndex]);

  return <>{countdown}</>
}

function Histogram({ historyArray, activeRow }) {
  const max = Math.max(10, ...historyArray);
  return <div className="chart-container">
    {historyArray.map((value, i) => value != null && (
      <div className="chart-line" key={i}>
        {i}
        <span
          className={`bar ${activeRow == i ? "active" : ""}`}
          style={{ width: `${100 * value / max}%` }}
        >{value}</span>
      </div>
    ))}
  </div>
}

export function StatDialog({
  isOpen,
  setClosed,
  streak,
  numPlayed,
  numWon,
  maxStreak,
  todayIndex,
  todayScoreList,
  todayOptions,
  todayPlayed,
  guessesHistory,
  isEasyMode,
  emojiStr,
  showShareLink,
}) {
  const onShareClick = () => {
    const shareData = {
      text: `Orderdle ${todayIndex}${isEasyMode ? "*" : ""}\n`
        + createShareEmojiBlock(todayOptions, todayScoreList, emojiStr)
        + (showShareLink ? "\nPlay at orderdle.com" : "")
    };
    console.log(shareData.text)
    if (!navigator?.share && navigator.canShare(shareData)) {
      alert('navigator.share() not supported.');
      return;
    }
    navigator.share(shareData);
  }
  return (
    <CustomDialog isOpen={isOpen} setClosed={setClosed}>
      <div className="head-row">Your Stats</div>
      <div className="stats-wrap">
        <div className="num-row">
          <div className="num-tile">
            {numPlayed} <div className="tile-title">Played</div>
          </div>
          <div className="num-tile">
            {Math.round((numWon / numPlayed) * 100) || 0}% <div className="tile-title">Win %</div>
          </div>
          <div className="num-tile">
            {streak} <div className="tile-title">Current Streak</div>
          </div>
          <div className="num-tile">
            {maxStreak} <div className="tile-title">Max Streak</div>
          </div>
        </div>
        <Histogram
          historyArray={guessesHistory}
          activeRow={
            todayPlayed && todayScoreList?.length &&
            todayScoreList[todayScoreList.length - 1]?.score == 1 &&
            todayScoreList.length
          }
        />
      </div>
      <div className="countdown-label">Next puzzle available in...</div>
      <div className="countdown"><Countdown todayIndex={todayIndex} /></div>
      {todayPlayed && todayScoreList?.length > 0 && todayOptions?.length > 0 && (
        <button className="shareBtn" onClick={onShareClick}>
          <FontAwesomeIcon icon={faShareNodes} /> Share
        </button>
      )}
    </CustomDialog>
  );
}
export function HelpDialog({ isOpen, setClosed }) {
  return (
    <CustomDialog isOpen={isOpen} setClosed={setClosed}>
      <div className="head-row">How Do I Play?</div>
      <p>
        Reorder the list based on the given prompt. Give it your best guess and hit the button!
        Your guess will be given a grade based on <em>relationships</em> between each pair of items.
      </p>
      <p>
        Say the prompt is to place A, B, C, and D in alphabetical order...
      </p>
      <p>
        For a list of 4 options, there are 6 relationships (A-to-B, A-to-C, B-to-C, etc.)
      </p>
      <div className="example-box">
        <ScoreBox score={0} guessLabels={["D", "C", "B", "A"]} />
        <p>
          The only way to get 0% is to have the items in reverse order.
        </p>
        <ScoreBox score={1 / 6} guessLabels={["D", "C", "A", "B"]} />
        <p>
          This guess gets a 1 out of 6. Even though everything is in the wrong spot, you identified correctly that A comes before B.
        </p>
        <ScoreBox score={1 / 2} guessLabels={["A", "D", "C", "B"]} />
        <p>
          This guess gets a 3 out of 6. Fun fact: if you reverse this guess, the score will also be 50%.
        </p>
        <ScoreBox score={5 / 6} guessLabels={["A", "C", "B", "D"]} />
        <p>
          This guess gets a 5 out of 6. Only the relationship between B and C is wrong.
        </p>
        <ScoreBox score={1} guessLabels={["A", "B", "C", "D"]} />
        <p>
          This guess is fully correct.
        </p>
        <p>
          You can solve puzzles from the past week,
          but only today's puzzle will count toward your stats.
        </p>
      </div>
    </CustomDialog>
  );
}

export function SettingsDialog({
  isOpen,
  setClosed,
  theme,
  setTheme,
  isMetricMode,
  setIsMetricMode,
  isEasyMode,
  setIsEasyMode,
  showShareLink,
  setShowShareLink,
}) {
  const isDark = theme === "dark";
  return (
    <CustomDialog isOpen={isOpen} setClosed={setClosed}>
      <div className="head-row">Settings</div>
      <div className="settings-grid">
        <span>Dark Mode</span>

        <label className={`dark-light toggle-switch ${isDark ? "switched" : ""}`}>
          <input type="checkbox" checked={isDark} onChange={() => setTheme(isDark ? "light" : "dark")} />
          <FontAwesomeIcon className="moon" icon={faMoon} color="black" />
          <FontAwesomeIcon className="sun" icon={faSun} color="white" />
        </label>
        <span>Prefer Metric</span>
        <label className={`toggle-switch ${isMetricMode ? "switched" : ""}`}>
          <input
            type="checkbox"
            checked={isMetricMode}
            onChange={() => setIsMetricMode(!isMetricMode)}
          />
        </label>
        <span>Easy Mode</span>
        <label className={`toggle-switch ${isEasyMode ? "switched" : ""}`}>
          <input
            type="checkbox"
            checked={isEasyMode}
            onChange={() => setIsEasyMode(!isEasyMode)}
          />
        </label>
        <span>Add play link when sharing</span>
        <label className={`toggle-switch ${showShareLink ? "switched" : ""}`}>
          <input
            type="checkbox"
            checked={showShareLink}
            onChange={() => setShowShareLink(!showShareLink)}
          />
        </label>
      </div>
    </CustomDialog>
  );
}

const dateOptions = { month: "short", day: "numeric" };
const weekdayOptions = { weekday: "short" };
const dayOffsets = [-5, -4, -3, -2, -1, 0, 1];

export function PlayHistoryDialog({ isOpen, setClosed, weeklyPlayed, onSelectDay, disabled, todayIndex }) {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    setNow(Date.now());
  }, [isOpen]);

  const histButtons = dayOffsets.map((offset) => {
    const dayIdx = todayIndex + offset;
    const dayMs = START_MS + (dayIdx * MS_PER_DAY);
    const date = new Date(dayMs);
    return (
      <button
        key={dayIdx}
        className="history-btn"
        onClick={() => {
          setClosed();
          onSelectDay(dayIdx);
        }}
        disabled={disabled || dayMs > now || dayIdx <= 0}
      >
        <div>{date.toLocaleDateString(undefined, dateOptions)}</div>
        {weeklyPlayed?.includes(dayIdx) && <div className="check">✔</div>}
        <div className="weekday">{date.toLocaleDateString(undefined, weekdayOptions)}</div>
        {dayMs > now && <div className="countdown-sm">
          <Countdown todayIndex={todayIndex} endCallback={() => setNow(Date.now())}/>
        </div>}
      </button>
    );
  });

  return (
    <CustomDialog isOpen={isOpen} setClosed={setClosed}>
      <div className="head-row">Solve more puzzles from this week!</div>
      {disabled &&
        <div className="disabled-notice">Please finish the current game before selecting another one.</div>
      }
      <div className="history-row">
        {histButtons.slice(0, -2)}
        <div className="flex">{histButtons.slice(-2)}</div>
      </div>
    </CustomDialog>
  );
}
