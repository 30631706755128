import { useEffect } from "react";
import { useLocalStorage } from "./util";

const CURRENT_VERSION = "0.1";
export function useAutoPatcher() {
  const [version, setVersion] = useLocalStorage("v", CURRENT_VERSION);
  useEffect(() => {
    if (version === CURRENT_VERSION) return;
    const theme = localStorage.getItem("theme");
    if (version === "0.0") {
      localStorage.removeItem("w");
      setVersion("0.1");
    }
  }, [version]);
  return;
}
