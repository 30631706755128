import { getPuzzleForDay } from "./loader";
import { getTodayIndex, getWinMessage, gradeOrder, NUM_GUESSES } from "./util";
import { useEffect, useState } from "react";
import Header from "./Header";
import { StatDialog, HelpDialog, SettingsDialog, PlayHistoryDialog } from "./Dialogs";
import ScoreList from "./ScoreList";
import { useLocalStorage } from "./util";
import "./App.css";
import {
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadAnimation from "./LoadAnimation";
import { useAutoPatcher } from "./version";


function App() {
  useAutoPatcher();
  const [prompt, setPrompt] = useState(null);
  const [emojiStr, setEmojiStr] = useState(null);

  const [dayIndex, setDayIndex] = useState(getTodayIndex());
  const [todayIndex, setTodayIndex] = useLocalStorage("t", null);
  const [options, setOptions] = useState([]);
  const [statsOpen, setStatsOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [guessDisabled, setGuessDisabled] = useState(false);
  const [loadAnimationFinished, setLoadAnimationFinished] = useState(true);
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage("theme", defaultDark ? "dark" : "light");

  const [scoreList, setScoreList] = useState([]);
  const [todayScoreList, setTodayScoreList] = useLocalStorage("l", []);
  const [todayOptions, setTodayOptions] = useLocalStorage("a", null);
  const [guessesHistory, setGuessesHistory] = useLocalStorage("h", [null, 0, 0, 0, 0, 0, 0]);
  const [gameOver, setGameOver] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState("");

  const [firstTime, setFirstTime] = useLocalStorage("f", 1);
  const [lastDaily, setLastDaily] = useLocalStorage("d", null);
  const [streak, setStreak] = useLocalStorage("s", 0);
  const [maxStreak, setMaxStreak] = useLocalStorage("m", 0);
  const [numPlayed, setNumPlayed] = useLocalStorage("n", 0);
  const [numWon, setNumWon] = useLocalStorage("o", 0);
  const [weeklyPlayed, setWeeklyPlayed] = useLocalStorage("p", []);
  const [isMetricMode, setIsMetricMode] = useLocalStorage("i", false);
  const [isEasyMode, setIsEasyMode] = useLocalStorage("e", false);
  const [showShareLink, setShowShareLink] = useLocalStorage("sl", true);

  useEffect(() => {
    document.body.style.backgroundColor = theme == "dark" ? "black" : "white";
  }, [theme])

  // first time visitor
  useEffect(() => {
    if (firstTime == 1) {
      setTimeout(() => setHelpOpen(true), 500)
      setFirstTime(0);
    }
  }, []);

  // load puzzle based on dayIndex
  useEffect(() => {

    // confirm today index is set correctly
    const actualTodayIndex = getTodayIndex();
    const storedTodayIndex = todayIndex;
    if (storedTodayIndex !== actualTodayIndex) {
      // clear out stored today vars
      setTodayScoreList([]);
      setTodayOptions(null);
      setTodayIndex(actualTodayIndex);
      return; // this useEffect will rerun
    }

    // now that todayIndex is up to date...

    // setup for fresh puzzle
    setLoadAnimationFinished(false);
    setGameOver(false);
    setGameOverMessage("");
    setGuessDisabled(false);
    setStatsOpen(false);
    setScoreList([]);

    async function loadPuzzle() {
      const puzzle = await getPuzzleForDay(dayIndex);
      setPrompt(puzzle.prompt);
      setOptions(puzzle.options.map(x => ({
        id: x.value,
        ...x
      })));

      if (dayIndex != todayIndex) return;
      setEmojiStr(puzzle.emoji);
      // loaded today's puzzle, try to populate from stored vars
      setScoreList([...todayScoreList]);
      if (todayOptions) setOptions(todayOptions);
      if (weeklyPlayed.includes(todayIndex)) {
        // already finished today's puzzle
        setLoadAnimationFinished(true);
        setGameOver(true);
        setGuessDisabled(true);
        setStatsOpen(true);
      }
      return;
    }

    loadPuzzle();
  }, [dayIndex, todayIndex]);

  const endGame = (gameWasWon) => {
    // note: scoreList is missing final entry
    setGameOver(true);
    setWeeklyPlayed([...new Set([...weeklyPlayed, dayIndex])].filter(x => x > todayIndex - 7));
    if (gameWasWon) {
      setGameOverMessage(getWinMessage(scoreList.length + 1));
    }

    if (todayIndex !== dayIndex) return; // skip stats if not today's game

    setTimeout(() => setStatsOpen(true), 1000);
    setNumPlayed(numPlayed + 1);
    if (!gameWasWon) {
      setStreak(0);
      return;
    }


    if (Number(lastDaily) == todayIndex - 1) {
      const newStreak = streak + 1;
      setStreak(newStreak);
      setMaxStreak(Math.max(maxStreak, newStreak));
    } else {
      setStreak(1);
      setMaxStreak(Math.max(maxStreak, 1));
    }

    setLastDaily(todayIndex);
    setNumWon(numWon + 1);

    const newGuessesHistory = [...guessesHistory];
    newGuessesHistory[scoreList.length + 1]++;
    setGuessesHistory(newGuessesHistory);
  };

  const submitGuess = () => {
    setGuessDisabled(true);
    const guess = options.map((x) => x.value);
    const guessLabels = options.map((x) => x.label);
    const score = gradeOrder(guess);
    const newScoreList = [
      ...scoreList,
      {
        score,
        guessLabels,
      },
    ];
    setScoreList(newScoreList);
    if (todayIndex === dayIndex) {
      setTodayScoreList(newScoreList);
      setTodayOptions(options);
    }

    if (score === 1) {
      // won
      endGame(true);
    } else if (scoreList.length + 1 === NUM_GUESSES) {
      // lost
      endGame(false);
    } else {
      // easy mode reveal
      const toReveal = options.find(x => !x.revealed);
      toReveal.revealed = true;
      // avoid revealing the final one
      if (options.every(x => x.revealed)) toReveal.revealed = false;
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!active || !over) {
      console.log("drag end null", active, over);
      return;
    }
    if (active.id !== over.id) {
      setGuessDisabled(false);
      setOptions((items) => {
        const oldIndex = items.findIndex(x => x.id === active.id);
        const newIndex = items.findIndex(x => x.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <div className={`root-inner ${theme === "dark" ? "dark" : ""}`}>
      <HelpDialog isOpen={helpOpen} setClosed={() => setHelpOpen(false)} />
      <StatDialog
        todayIndex={todayIndex}
        isOpen={statsOpen}
        setClosed={() => setStatsOpen(false)}
        streak={streak}
        numPlayed={numPlayed}
        numWon={numWon}
        maxStreak={maxStreak}
        todayScoreList={todayScoreList}
        todayOptions={todayOptions}
        todayPlayed={weeklyPlayed.includes(todayIndex)}
        guessesHistory={guessesHistory}
        isEasyMode={isEasyMode}
        emojiStr={emojiStr}
        showShareLink={showShareLink}
      />
      <SettingsDialog
        isOpen={settingsOpen}
        setClosed={() => setSettingsOpen(false)}
        theme={theme}
        setTheme={setTheme}
        isMetricMode={isMetricMode}
        setIsMetricMode={setIsMetricMode}
        isEasyMode={isEasyMode}
        setIsEasyMode={setIsEasyMode}
        showShareLink={showShareLink}
        setShowShareLink={setShowShareLink}
      />
      <PlayHistoryDialog
        isOpen={historyOpen}
        setClosed={() => setHistoryOpen(false)}
        weeklyPlayed={weeklyPlayed}
        onSelectDay={dayIdx => setDayIndex(dayIdx)}
        disabled={scoreList.length !== 0 && !gameOver}
        todayIndex={todayIndex}
      />
      <Header setStatsOpen={setStatsOpen} setHelpOpen={setHelpOpen} setSettingsOpen={setSettingsOpen} setHistoryOpen={setHistoryOpen} />
      {!loadAnimationFinished || !prompt ? <LoadAnimation onFinish={() => setLoadAnimationFinished(true)} /> :
        <>
          <div className="prompt">
            {prompt?.split("\n").map((x, i) => <div key={i}>{x}</div>)}
            {gameOverMessage && <div className="game-over-msg">{gameOverMessage}</div>}
          </div>
          {options &&
            <div className="drag-container">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  disabled={gameOver}
                  items={options}
                  strategy={verticalListSortingStrategy}
                >
                  {options?.map(option => <SortableItem
                    key={option.value}
                    id={option.id}
                    disabled={gameOver}
                  >
                    <FontAwesomeIcon className="grip-icon" icon={faGripLines} />
                    {option.label}
                    {(gameOver || (isEasyMode && option.revealed)) && <span className="answer">
                      {(isMetricMode && option.metricText) || option.valueText}
                    </span>}
                  </SortableItem>)}
                </SortableContext>
              </DndContext>
            </div>}
          <button className="guess-btn" onClick={submitGuess} disabled={guessDisabled}>
            Guess!
          </button>
          <ScoreList scoreList={scoreList} />
        </>}

    </div>
  );
}

export default App;
