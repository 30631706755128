import { NUM_GUESSES } from "./util";
import { Tooltip, TooltipProvider, TooltipWrapper } from 'react-tooltip';
import "./ScoreList.css";
import 'react-tooltip/dist/react-tooltip.css';

export function getScoreDisplay(score) {
  const percentage = score * 100;
  if (percentage === 100) return "💯";
  return Math.round(percentage).toString() + "%";
}

export function getScoreFontWeight(score) {
  if (score == 1) return 400;
  if (score > .79) return 700;
  return 400;
}

export function ScoreBox({ score, guessLabels, idx }) {
  return (
    <div className="score-box">
      {guessLabels.map((text, i) => {
        const shouldShowPopover = text.length > 3;
        const content = <div className="guess" key={text}>
          <div className="guess-label">
            {text}
          </div>
        </div>
        if (!shouldShowPopover) return <div className="guess" key={text}>
          {content}
        </div>

        return <TooltipWrapper
          key={text}
          content={text}
        >
          {content}
        </TooltipWrapper>
      })}
      <span className="score" style={{ fontWeight: getScoreFontWeight(score) }}>{getScoreDisplay(score)}</span>
    </div>
  );
}

export default function ScoreList({ scoreList }) {
  const boxes = [];
  for (let i = 0; i < NUM_GUESSES; i++) {
    const scoreObj = scoreList[i];
    if (!scoreObj) {
      boxes.push(<div key={i} className="score-box"></div>);
    } else {
      boxes.push(
        <ScoreBox
          key={i}
          idx={i}
          score={scoreObj.score}
          guessLabels={scoreObj.guessLabels}
        />
      );
    }
  }

  return (
    <TooltipProvider>
      <div className="score-container">{boxes}</div>
      <Tooltip className="tooltip" />
    </TooltipProvider>
  );
}
