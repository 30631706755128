import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export async function getPuzzleForDay(dayIndex) {
  console.log("load",dayIndex);
  const docRef = doc(db, "puzzles", dayIndex.toString());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("failed to get today's doc!");
  }
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
