import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyADZbvRewqEYPiPRcWqXYgkIRQiuxL_V7g",
  authDomain: "orderdle.firebaseapp.com",
  projectId: "orderdle",
  storageBucket: "orderdle.appspot.com",
  messagingSenderId: "189876240168",
  appId: "1:189876240168:web:c377cb02130f40be1eda1d",
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
