import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faCircleQuestion, faGear, faHistory } from "@fortawesome/free-solid-svg-icons";

import "./Header.css";
export default function Header({ setStatsOpen, setHelpOpen, setSettingsOpen, setHistoryOpen }) {
  return (
    <div className="header-row">
      <FontAwesomeIcon className="head-icon" icon={faCircleQuestion} onClick={() => setHelpOpen(true)} />
      <FontAwesomeIcon className="head-icon" icon={faHistory} onClick={() => setHistoryOpen(true)} />
      <h1>Orderdle</h1>
      <FontAwesomeIcon className="head-icon" icon={faChartColumn} onClick={() => setStatsOpen(true)} />
      <FontAwesomeIcon className="head-icon" icon={faGear} onClick={() => setSettingsOpen(true)} />
    </div>
  );
}
